import { RiFacebookBoxFill, RiTwitterFill, RiInstagramLine, RiLinkedinBoxFill, RiPinterestFill, RiTiktokFill } from "react-icons/ri";
import { Navigate, Outlet } from "react-router";
import Social1 from '../Assets/images/static/Social/1.png';
import Social2 from '../Assets/images/static/Social/2.png';
import Social3 from '../Assets/images/static/Social/3.png';
import Social4 from '../Assets/images/static/Social/4.png';
import Social5 from '../Assets/images/static/Social/5.png';
import Social6 from '../Assets/images/static/Social/6.png';
import Social7 from '../Assets/images/static/Social/7.png';
import Social8 from '../Assets/images/static/Social/9.png';
//-------------------------------------------------url
export const base_url = "https://firerockdiamonds.com/webapi/api";
export const img_url = "https://firerockdiamonds.com";
export const helmet_url = "https://firerockdiamonds.com";

// ------Contact Details Start ------
export const contact_number = {                // <===---contact Number //
  text: '+1 347-325-2725',
  link: `tel:+1347-325-2725`
};

export const Email_details = {                      // <===---Email_Details  //
  text: 'firerockdiamonds@gmail.com',
  link: `mailto:firerockdiamonds@gmail.com,`
};

export const Email_detailsSecond = {                // <===---Second_Email_details  // 
  text: 'david@firerockdiamonds.com',
  link: `mailto:david@firerockdiamonds.com`
};

export const Address_details = {                     // <===---Address_details  // 
  textLine1: '600 5th Avenue',
  textLine2: 'New York, NY 10020',
  link: "https://www.google.com/maps/place/Firerock+Diamonds/@40.7586731,-73.9784981,15z/data=!4m6!3m5!1s0x89c259003a6d7c13:0x8f3825b31c07e395!8m2!3d40.7586731!4d-73.9784981!16s%2Fg%2F11j4vr1vh7?entry=ttu"
};

export const client_name = {                          // <===---client_name  // 
  c_name: "Firerock Diamond's",
};

export const media_details = [                           // <===---media_details  // 
  {
    account_alt: 'Facbook',
    account_icon: <RiFacebookBoxFill />,
    account_img: Social1,
    account_link: "https://www.facebook.com/Firerock_diamonds/"
  },
  {
    account_alt: 'Instgram',
    account_icon: <RiInstagramLine />,
    account_img: Social2,
    account_link: "https://www.instagram.com/Firerock_diamonds/"
  },

  {
    account_alt: 'Tiktok',
    account_icon: <RiTiktokFill />,
    account_img: Social8,
    account_link: "https://www.tiktok.com/@firerockdiamonds"
  },
];


export const InvoiceAddress = () => {              // <===---Invoice_Address  //
  return (
    <>
      <h4 className="mb-2">20 W 47th St, Suite 601</h4>
      <h4 className="mb-0"> New York, NY 10036</h4>
    </>
  )
};
// ------Contact Details End ------

export const user = JSON.parse(localStorage.getItem("bw-user")) ? JSON.parse(localStorage.getItem("bw-user")) : [];

export const isLogin = JSON.parse(localStorage.getItem("bw-user")) ? JSON.parse(localStorage.getItem("bw-user")).isLogin : false;
export const currencycode = localStorage.getItem("bw-currency") ? localStorage.getItem("bw-currency") : 'USD';
export const currency = localStorage.getItem("bw-currencyicon") ? localStorage.getItem("bw-currencyicon") : '$';
//--------------------------------------------------Api header data
export const postHeader = {
  // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

export const onTop = () => {
  window.scrollTo(0, 0);
}

export const ProtectedRoutes = () => {
  return isLogin ? <Outlet /> : <Navigate to="/" />
}
//---------------------------------------------------Ring builder
export const setSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem('bw-diamonddata')) ? JSON.parse(localStorage.getItem('bw-diamonddata')) : null;
  const settingdata = JSON.parse(localStorage.getItem('bw-settingdata')) ? JSON.parse(localStorage.getItem('bw-settingdata')) : null;
  const gemstonedata = JSON.parse(localStorage.getItem('bw-gemstonedata')) ? JSON.parse(localStorage.getItem('bw-gemstonedata')) : null;
  const fancycolordata = JSON.parse(localStorage.getItem('bw-fancycolordata')) ? JSON.parse(localStorage.getItem('bw-fancycolordata')) : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  }
}

//---------------------------------------------------stud builder
export const setStudSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem('bw-stud-diamonddata')) ? JSON.parse(localStorage.getItem('bw-stud-diamonddata')) : null;
  const settingdata = JSON.parse(localStorage.getItem('bw-stud-settingdata')) ? JSON.parse(localStorage.getItem('bw-stud-settingdata')) : null;
  const gemstonedata = JSON.parse(localStorage.getItem('bw-stud-gemstonedata')) ? JSON.parse(localStorage.getItem('bw-stud-gemstonedata')) : null;
  const fancycolordata = JSON.parse(localStorage.getItem('bw-stud-fancycolordata')) ? JSON.parse(localStorage.getItem('bw-stud-fancycolordata')) : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  }
}


//------------------- Image error------------------------

export const ErrorImg = (dia_shape, id, type) => {
  if (dia_shape == "Round") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/round.jpg";
  } else if (dia_shape == "Princess") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/princess.jpg";
  } else if (dia_shape == "Cushion") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/cushion.jpg";
  } else if (dia_shape == "Emerald") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/emerald.jpg";
  } else if (dia_shape == "Oval") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/oval.jpg";
  } else if (dia_shape == "Radiant") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/radiant.jpg";
  } else if (dia_shape == "Asscher") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/asscher.jpg";
  } else if (dia_shape == "Marquise") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/marquise.jpg";
  } else if (dia_shape == "Heart") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/heart.jpg";
  } else if (dia_shape == "Pear") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/pear.jpg";
  } else if (dia_shape == "Gemstone") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/gemstone.png";
  } else if (dia_shape == "Fancy") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/fancy_diamond.png";
  } else {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/diamond.png";
  }

  if (type == "detail" || type == "Complete") {
    if (type == "Complete") {
      var imgsrc = document.getElementsByClassName("image-gallery-thumbnail-image")[1].getAttribute("src");
      var image = new Image();
      image.src = imgsrc;
      image.onerror = () => {
        document.getElementsByClassName("image-gallery-thumbnail-image")[1].src = img;
      }
    } else {
      var imgsrc = document.getElementsByClassName("image-gallery-thumbnail")[0].getElementsByTagName("img")[0].getAttribute("src");
      var image = new Image();
      image.src = imgsrc;
      image.onerror = () => {
        document.getElementsByClassName("image-gallery-thumbnail-image")[type == "Complete" ? 1 : 0].src = img;
        document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = img;
      }
    }
    image.onload = () => {
      document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = imgsrc;
    }
  } else {
    console.log("ImageError sku: ", id)
    document.getElementById(id).src = img;
  }
}