import React from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import Gimg from "../../../../Assets/images/home/google-img.webp";
import googleReview from "../../../../Assets/images/home/google-review-1.png";
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
// import Review from "../Assets/img/review.png"  
import Review from "../../../../Assets/images/firerock-daimonds-img/greview.png"
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { base_url, postHeader } from '../../../../Helpers/request';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Container from '../ui/Container';
import Heading from '../ui/Heading';
// import SlickLeft from "../Assets/img/left.png"
// import SlickRight from "../Assets/img/right.png"
import SlickLeft from "../../../../Assets/images/firerock-daimonds-img/right.png"
import SlickRight from "../../../../Assets/images/firerock-daimonds-img/left.png"
import { isDesktop, isMobile } from 'react-device-detect';

const Customer = () => {
    const [customerData,setCustomerData] = React.useState([])
    React.useEffect(()=>{
        GetCustomerRewies();
        console.log('customerdta',customerData)
     },[])
     const GetCustomerRewies = () =>{
         axios.get(`${base_url}/common/site_reviews`,{
            headers: postHeader
        }).then(res=>{
            console.log(res)
            if(res.data.status == 1){
                setCustomerData(res.data)
            }
        }).catch(error=>{
            console.log(error)
        })
     }

    let settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        prevArrow: <Image src={SlickLeft} alt='' />,
        nextArrow: <Image src={SlickRight} alt=''  />,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false,
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: true,
                    dots:false,
                }
            }
            
        ]
    };
    const reviewData = [
        {
            reviewName:"Nancy Hernandez",
            reviewText:"It was a pleasure working with David! His attention to detail and customer service are unmatched! I appreciated the updates and patience he had while designing my ring.  Definitely recommend Firerock Diamonds!",
        },
        {
            reviewName:"Karen Dela Cruz",
            reviewText:"David is the best jeweler hands down in Manhattan. He is not a gimmicky typical sales man that will make you feel pressured to purchase something. This man’s passion for diamonds shows in his knowledge, he is patient and generous with your time & will help you find exactly what you are looking You can tell he lives and breathes this industry and loves to make people happy.",
        },
        {
            reviewName:"Michael Martin",
            reviewText:"David was an amazing help to make my special day that much easier and more beautiful. He constantly stays in contact and will cater to whatever questions needs or concerns you have. Very friendly and amazing prices for amazing pieces",
        },
        {
            reviewName:"jus s",
            reviewText:"I found David of Firerock Diamonds by luck and I am so, very happy that I did. My now fiancé and I are a young couple and haven’t ever been ring shopping together before, so naturally we were both nervous & unfamiliar with the process",
        },
        {
            reviewName:"Desiree Butts",
            reviewText:"I can’t express how amazed I am by David and his team a Firerock Diamonds! He took all my desires and made it into this gorgeous ring! He was always in contact with me with all the details! David went above and beyond to make sure my ring …",
        },
    ]

    return (
        <section className="rcs_customer_say_section">
            <Container className="showcase-container">
                    {/* <div className='d-flex align-items-center justify-content-between pb-3'> */}
                    <div className='pb-3'>
                        {/* <Heading className='text-left p-0'> */}
                        <Heading className='text-center p-0'>
                            {/* <h2>Happy Customers</h2> */}
                            <h2> Our Customer Feedback</h2>
                            {/* <p>Words of Praise</p> */}
                            <p>Don’t take our word for it. Trust our customers.</p>
                        </Heading>

                       {/* {isDesktop &&  <div className="rcs_customer_review_btn">
                            <Button className="btn outline-base-btn large" onClick={() => window.open(customerData?.google_review_url, "_blank")}>
                                 Write a Review </Button>
                        </div>} */}
                    </div>
                    
                <Row>
                    <Col lg={12} data-aos="fade-up" data-aos-duration="1400">
                        <div className="rcs_customer_review_slider">
                            <Slider className="rcs_customer_say_inner" {...settings2}>
                            {reviewData.map(customdata => 
                                <div className="rcs_customer_review_slide_item">
                                    <div className="rcs_customer_slide_title_img">
                                        <div className="rcs_customer_review_slide_img">
                                            <LazyLoadImage src={Review} alt="Google Review Image"  
                                            onClick={() => window.open("https://www.google.com/search?q=firer+ock+daimonds&rlz=1C1CHZN_enIN1077IN1077&oq=fir&gs_lcrp=EgZjaHJvbWUqBggCEEUYOzIGCAAQRRg8MgYIARBFGDkyBggCEEUYOzIGCAMQRRg8MgYIBBBFGD0yBggFEEUYPDIGCAYQRRg8MgYIBxBFGEHSAQgxOTg0ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x89c259003a6d7c13:0x8f3825b31c07e395,1,,,,", "_blank")}/>
                                        </div>
                                    </div>
                                    <div className='rcs_customer_review_footer d-flex align-items-center'>
                                        {/* <div className='name-charAt mr-3'>{customdata?.username.charAt(0)}</div> */}
                                        <div className="rcs_customer_review_slide_title">
                                            <h3>{customdata?.reviewName}</h3>
                                            <Rating name="half-rating-read" defaultValue={5} precision={customdata?.rating} size="medium" readOnly />
                                        </div>
                                    </div>
                                    <div className="rcs_customer_slide_text_content">
                                        <p>
                                            {(customdata?.reviewText)?.slice(0,150)}{ customdata?.reviewText?.length > 150 ? '...' : ""}
                                        </p>
                                        <Link to="#" onClick={() => window.open("https://www.google.com/search?q=firer+ock+daimonds&rlz=1C1CHZN_enIN1077IN1077&oq=fir&gs_lcrp=EgZjaHJvbWUqBggCEEUYOzIGCAAQRRg8MgYIARBFGDkyBggCEEUYOzIGCAMQRRg8MgYIBBBFGD0yBggFEEUYPDIGCAYQRRg8MgYIBxBFGEHSAQgxOTg0ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x89c259003a6d7c13:0x8f3825b31c07e395,1,,,,", "_blank")}>  Read More </Link>
                                    </div>
                                    {/* <div className='rcs_customer_review_footer d-flex align-items-center'>
                                        <div className='name-charAt mr-3'>{customdata?.username.charAt(0)}</div>
                                        <div className="rcs_customer_review_slide_title">
                                            <h3>{customdata?.username}</h3>
                                            <Rating name="half-rating-read" defaultValue={5} precision={customdata?.rating} size="medium" readOnly />
                                        </div>
                                    </div> */}
                                </div>)}
                            </Slider>
                        </div>
                    </Col>
                </Row>

                {/* {isMobile &&  <div className="rcs_customer_review_btn text-center mt-5">
                            <Button className="btn outline-base-btn large" onClick={() => window.open(customerData?.google_review_url, "_blank")}> Write a Review </Button>
                        </div>} */}
            </Container>
        </section>
    );
}

export default Customer;
